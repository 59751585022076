/* Archivo principal de Javascript del tema ClickEnNet */
/* Usa JQuery */

/* Metodo para el efecto suave al bajar la página cuando se pulsa un link del nav */

$('a[href^="#"]').click(function(e) {

	$('html,body').animate({ scrollTop: $(this.hash).offset().top}, 1000);

	return false;

	e.preventDefault();

});

/* Metodo para el efecto suave al subir la página cuando se pulsa el boton fijo de scrollTop */

// Metodo que determina la visibilidad del boton scrollTop
// para cambiar la distancia se cambia el 100 que viene por defecto.
$(window).scroll(function(){

	if ($(this).scrollTop() > 100) {
		$('.scrollToTop').fadeIn();
	} else {
		$('.scrollToTop').fadeOut();
	}

});

// Evento click que activa la animacion para subir arriba de la página
$('.scrollToTop').click(function(e){

	$('html, body').animate({ scrollTop : 0 }, 1000);

	return false;

	e.preventDefault();

});

// alerta de cookies en nuestra pagina
window.onload = function() {
	checkCookieLaw();
};

function checkCookieLaw(){
	if (window.localStorage["cookieLawKeyMiWeb"] == "true" ){
		$('#avisoCookies').hide();
	}
	var cookies = localStorage.getItem("cookieLawKeyMiWeb");
}

$('#acceptarCookies').click(function(e){

	localStorage.setItem("cookieLawKeyMiWeb", "true");

	$('#avisoCookies').fadeOut();

	var cookie = localStorage.getItem("cookieLawKeyMiWeb");

});

$('.carousel-control.left').click(function() {
  $('#home_slider').carousel('prev');
});

$('.carousel-control.right').click(function() {
  $('#home_slider').carousel('next');
});


